import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import Rails from '@rails/ujs';
window.Rails = Rails;
Rails.start();

import MicroModal from 'micromodal';
window.MicroModal = MicroModal;

document.addEventListener('DOMContentLoaded', function () {
  const swiper = new Swiper('.swiper', {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    loop: true,
    effect: "fade",
    autoplay: {
      delay: 4000,
    },
  });
});
